<template>
    <div>
        <div class="filter-panel">
            <CSSelect style="margin-right: 20px">
                <select
                        v-model="timeSelect"
                        style="color: #999"
                        :style="{
                        color: timeSelect != '' ? '#000' : '#999',
                    }"
                >
                    <option value="">自定义</option>
                    <option
                            v-for="(state, key) in timeSelectList"
                            :key="key"
                            :value="key"
                            style="color: #000"
                    >
                        {{ state }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 0;">
                <el-date-picker
                        :disabled="timeSelectEnable"
                        v-model="startDate"
                        type="date"
                        :picker-options="{disabledDate: elDisabledDate}"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择开始时间"
                        style="width: 140px"
                        prefix-icon="el-icon-time"
                        :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect style="margin-right: 30px;">
                <el-date-picker
                        :disabled="timeSelectEnable"
                        v-model="endDate"
                        type="date"
                        :picker-options="{disabledDate: elDisabledDate}"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择结束时间"
                        style="width: 140px"
                        prefix-icon="el-icon-time"
                        :editable="false"
                ></el-date-picker>
            </CSSelect>
            <button class="btn btn-primary" @click="getData()">查询</button>
        </div>
        <div class="result-panel data" style="padding: 20px 0;">
            <ul>
                <li>
                    <span style="display: inline-block; height: 24px; line-height: 16px;">报事</span>
                    <a @click="response('workOrderFlow')">查看详情</a>
                </li>
                <li>
                    <ul>
                        <li>
                            报事数:{{ reportData.reportNum }}
                            <a
                                    href="#"
                                    @click="
                                    chartDialog.state = true;
                                    chartDialog.title = '报事数';
                                    chartDialog.build = () => {
                                        getTicketChartData(1);
                                    };
                                "
                            >环比/同比</a
                            >
                        </li>
                        <li>已处理数:{{ reportData.handleNum }}</li>
                        <li>超时处理数:{{ reportData.timeoutNum }}</li>
                        <li>评价数:{{ reportData.evaluationNum }}</li>
                        <li>平均星级:{{ reportData.avgScore }}星</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="result-panel data" style="padding: 20px 0;">
            <ul>
                <li>
                    <span style="display: inline-block; height: 24px; line-height: 16px;">投诉</span
                    ><a @click="response('workOrderFlow')">查看详情</a>
                </li>
                <li>
                    <ul>
                        <li>
                            投诉数:{{ complaintData.reportNum }}
                            <a
                                    href="#"
                                    @click="
                                    chartDialog.state = true;
                                    chartDialog.title = '投诉数';
                                    chartDialog.build = () => {
                                        getTicketChartData(2);
                                    };
                                "
                            >环比/同比</a
                            >
                        </li>
                        <li>已处理数:{{ complaintData.handleNum }}</li>
                        <li>超时处理数:{{ complaintData.timeoutNum }}</li>
                        <li>评价数:{{ complaintData.evaluationNum }}</li>
                        <li>平均星级:{{ complaintData.avgScore }}星</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="result-panel data" style="padding: 20px 0;">
            <ul>
                <li><span style="display: inline-block; height: 24px; line-height: 16px;">品质检查报告</span><a
                        @click="response('qualityReportLog')">查看详情</a></li>
                <li>
                    <ul>
                        <li>报告数:{{inspectionReportData.reportCount}}</li>
                        <li>合格率:{{inspectionReportData.passRate}}%</li>
                        <li>已完成整改数:{{inspectionReportData.finishRectifyCount}}</li>
                        <li>未完成整改数:{{inspectionReportData.notFinishRectifyCount}}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="result-panel data" style="padding: 20px 0;">
            <ul>
                <li>
                    <span style="display: inline-block; height: 24px; line-height: 16px;">巡检</span
                    ><a @click="response('inspectTaskFlow')">查看详情</a>
                </li>
                <li>
                    <ul>
                        <li>任务数:{{ inspectionData.taskNum }}</li>
                        <li>完成数:{{ inspectionData.finishNum }}</li>
                        <li>异常数:{{ inspectionData.abnormalNum }}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="result-panel data personnel" style="padding: 20px 0 0;">
            <ul>
                <li>
                    <span style="display: inline-block; height: 24px; line-height: 16px;">人事</span><a
                        @click="response('staff')">查看详情</a>
                </li>
                <li v-for="(item, n) in personnelData" :key="n" style="margin-top: 0">
                    <ul>
                        <li >
                            {{ item.name }}在职员工数:{{ item.existingNum }}
                        </li>
                        <li>
                            入职人数:{{ item.entryNum }}
                            <a
                                    href="#"
                                    @click="
                                    chartDialog.state = true;
                                    chartDialog.title = '入职人数';
                                    chartDialog.build = () => {
                                        getPersonnelOnboardingChartData(
                                            item.duptType
                                        );
                                    };
                                "
                            >环比/同比</a
                            >
                        </li>
                        <li>
                            离职人数:{{ item.resignNum }}
                            <a
                                    href="#"
                                    @click="
                                    chartDialog.state = true;
                                    chartDialog.title = '离职人数';
                                    chartDialog.build = () => {
                                        getPersonnelResignChartData(
                                            item.duptType
                                        );
                                    };
                                "
                            >环比/同比</a
                            >
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="result-panel data" style="padding: 20px 0 6px;">

            <ul>
                <li>
                    <span style="display: inline-block; height: 24px; line-height: 16px;">员工考核</span
                    ><a @click="response('AssessmentSummaryFlow')">查看详情</a>
                </li>
                <li>
                    <ul>
                        <li v-for="(item, n) in staffAssessmentData" :key="n">
                            {{ item.name }}员工总扣分:{{ item.deductionTotal }}
                            <a
                                    href="#"
                                    @click="
                                    chartDialog.state = true;
                                    chartDialog.title = '员工总扣分';
                                    chartDialog.build = () => {
                                        getStaffAssessmentChartData(
                                            item.dutyType
                                        );
                                    };
                                "
                            >环比/同比</a
                            >
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!--招商电子楼书统计-->
        <div class="building-book">
            <div style="font-size: 24px;
                        margin-left: 40px;
                        font-weight: bold;
                        line-height: 33px;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        color: black;">
                招商电子楼书访问统计
            </div>
            <div style="
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000000;
                        opacity: 1;">
                <span style="margin-left: 40px">招商部在职员工数：{{buildingBookData.total}}人</span>
                <span style="margin-left: 132px">UV独立访客总计：{{buildingBookData.uvCount}}人</span>
                <span style="margin-left: 133px">PV访问量总计：{{buildingBookData.pvCount}}人次</span>
            </div>
            <div style="padding-right: 60px">
                <table class="table modal-inner-table" style="--modal-inner-table-font-size:14px;">
                    <tr >
                        <td style="border-radius: 6px 0px 0px 0px; border-right: 1px solid #EEEEEE;">姓名</td>
                        <td style="border-right: 1px solid #EEEEEE;">岗位</td>
                        <td style="border-right: 1px solid #EEEEEE;">UV独立访客</td>
                        <td style="border-radius: 0px 6px 0px 0px;">PV访问量</td>
                    </tr>
                    <tbody :key="item.userId" v-for="(item) in buildingBookData.datas">
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        {{item.jobTitle}}
                    </td>
                    <td>
                            <span
                                    class="allow-click"
                                    @click="queryUV(item.uv,item.userId,item)"
                            >
                            {{item.uv}}

                        </span>
                    </td>
                    <td>
                            <span
                                    class="allow-click"
                                    @click="queryPV(item.pv,item.userId,item)"
                            >
                            {{item.pv}}
                        </span>

                    </td>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- 图表展示 -->
        <CSDialog
                :dialogTitle="chartDialog.title"
                dialogWidth="1120px" dialog-header-class="alert-bg"
                :dialogVisible="chartDialog.state"
                dialogCancelBtnText="关闭"
                :dialogShowConfirmBtn="false"
                @onClose="chartDialog.state = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px;"
            >
                <div class="text-center">
                    <el-date-picker
                            v-model="chartDateOne"
                            size="small"
                            style="border: 1px solid var(--bd-color, #979797);"
                            type="daterange"
                            range-separator="—"
                            :unlink-panels="false"
                            :picker-options="expireTimeOption"
                            start-placeholder="请选择"
                            end-placeholder="请选择"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    >
                    </el-date-picker>

                    <span
                            style="color: black; margin-left: 20px; margin-right: 20px"
                    >对比</span
                    >

                    <el-date-picker
                            v-model="chartDateTow"
                            style=" border: 1px solid var(--bd-color, #979797);"
                            size="small"
                            type="daterange"
                            range-separator="—"
                            :unlink-panels="false"
                            :picker-options="expireTimeOption"
                            start-placeholder="请选择"
                            end-placeholder="请选择"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    >
                    </el-date-picker>
                    <button
                            class="btn btn-primary"
                            style="margin-left: 30px"
                            @click="chartDialog.build()"
                    >
                        查询
                    </button>
                </div>
                <div
                        id="chart"
                        ref="rchart"
                        style="margin: 0 auto; width: 830px; height: 423px"
                ></div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
    import dayjs from "dayjs";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";

    import {
        queryTicketPropertyDataUrl,
        queryTicketPropertyChartDataUrl,
        queryInspectionPropertyDataUrl,
        queryEvaluationPropertyDataUrl,
        queryEvaluationPropertyChartDataUrl,
        queryAccountPropertyDataUrl,
        queryAccountPropertyChartDataUrl,
        queryReportPropertyDataUrl,
        queryUvPvByRegionUrl,
    } from "@/requestUrl";

    export default {
        name: "propertyData",
        components: {
            CSSelect,
            CSDialog,
        },
        data() {
            return {
                expireTimeOption: {
                    disabledDate(date) {
                        //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                        return date.getTime() > Date.now();
                    },
                },
                timeSelectList: {
                    //选择查询时间
                    1: "近7天",
                    2: "近14天",
                    3: "近30天",
                },
                timeSelectEnable: true,
                timeSelect: "1", //用户所选的查询时间
                //开始时间
                startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
                //结束时间
                endDate: dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
                //控制图表对话框
                chartDialog: {
                    state: false,
                    title: "",
                    build: () => {
                    },
                },
                chartDateOne: null, //查询图表数据第一组日期
                chartDateTow: null, //查询图表数据第二组日期
                reportData: {}, //报事数据
                complaintData: {}, //投诉数据
                inspectionData: {}, //巡检数据
                staffAssessmentData: {}, //员工考核数据
                personnelData: {}, //人事数据
                inspectionReportData: {},//品质检查报告数据
                buildingBookData: {}
            };
        },
        created() {
            window.addEventListener("keydown", this.getDataDown);
            this.queryUvPvByRegion();
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.getDataDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.getDataDown);
        },
        mounted() {
            this.getData();
        },
        watch: {
            timeSelect: function (val) {
                switch (val) {
                    case "1":
                        this.startDate = dayjs()
                            .subtract(7, "day")
                            .format("YYYY-MM-DD");
                        this.timeSelectEnable = true;
                        break;
                    case "2":
                        this.startDate = dayjs()
                            .subtract(14, "day")
                            .format("YYYY-MM-DD");
                        this.timeSelectEnable = true;
                        break;
                    case "3":
                        this.startDate = dayjs()
                            .subtract(30, "day")
                            .format("YYYY-MM-DD");
                        this.timeSelectEnable = true;
                        break;
                    case "":
                        this.timeSelectEnable = false;
                        break;
                }
            },
        },
        methods: {
            getDataDown(e) {
                if (e.keyCode == 13) {
                    this.getData();
                }
            },
            response(path) {
                this.$router.push({name: path, params: {return: 1}});
            },
            elDisabledDate(date) {
                return dayjs(date).isAfter(dayjs().subtract(1, 'day'));
            },
            getData() {
                this.getTicketData(1);
                this.getTicketData(2);
                this.getInspectionData();
                this.getPersonnelData();
                this.getStaffAssessmentData();
                this.getInspectionReportData();
            },
            buildChart(data) {
                var chart = this.$echarts.init(document.getElementById("chart"));
                var option = {
                    color: ["#000078", "#999999", "#474512"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                        },
                    },
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: data == null ? [] : data.title,
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                        },
                    ],
                    series: [
                        {
                            name: "",
                            type: "bar",
                            barWidth: "60%",
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        var colorList = [
                                            "#C1232B",
                                            "#B5C334",
                                            "#FCCE10",
                                            "#E87C25",
                                            "#27727B",

                                            "#FE8463",
                                            "#9BCA63",
                                            "#FAD860",
                                            "#F3A43B",
                                            "#60C0DD",

                                            "#D7504B",
                                            "#C6E579",
                                            "#F4E001",
                                            "#F0805A",
                                            "#26C0C0",
                                        ];

                                        return colorList[params.dataIndex];
                                    },
                                },
                            },
                            data: data == null ? [] : data.data,
                        },
                    ],
                };

                chart.setOption(option);
            },
            //获取品质检查报告数据
            getInspectionReportData() {
                const {startDate, endDate} = this;
                this.$fly.post(queryReportPropertyDataUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    startDate,
                    endDate
                })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.inspectionReportData = res.data;
                    });
            },
            //获取报事/投诉数据 type 1.报事2.投诉
            getTicketData(type) {
                const {startDate, endDate} = this;
                this.$fly
                    .post(queryTicketPropertyDataUrl, {
                        startDate,
                        endDate,
                        regionCode: this.$vc.getCurrentRegion().code,
                        type,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        switch (type) {
                            case 1:
                                this.reportData = res.data;
                                break;
                            case 2:
                                this.complaintData = res.data;
                                break;
                        }
                    });
            },
            //获取巡检数据
            getInspectionData() {
                const {startDate, endDate} = this;
                this.$fly
                    .post(queryInspectionPropertyDataUrl, {
                        startDate,
                        endDate,
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.inspectionData = res.data;
                    });
            },
            //获取人事数据
            getPersonnelData() {
                const {startDate, endDate} = this;
                this.$fly
                    .post(queryAccountPropertyDataUrl, {
                        startDate,
                        endDate,
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.personnelData = res.data;
                    });
            },
            //获取员工考核数据
            getStaffAssessmentData() {
                const {startDate, endDate} = this;
                this.$fly
                    .post(queryEvaluationPropertyDataUrl, {
                        startDate,
                        endDate,
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.staffAssessmentData = res.data;
                    });
            },
            //获取报事/投诉图表数据 type 1.报事2.投诉
            getTicketChartData(type) {
                const startDateOne = dayjs(this.chartDateOne[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateOne = dayjs(this.chartDateOne[1]).format("YYYY-MM-DD");
                const startDateTwo = dayjs(this.chartDateTow[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateTwo = dayjs(this.chartDateTow[1]).format("YYYY-MM-DD");
                this.$fly
                    .post(queryTicketPropertyChartDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        type,
                        startDateOne,
                        endDateOne,
                        startDateTwo,
                        endDateTwo,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.buildChart(res.data);
                    });
            },
            //获取员工考核图表数据
            getStaffAssessmentChartData(dutyType) {
                const startDateOne = dayjs(this.chartDateOne[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateOne = dayjs(this.chartDateOne[1]).format("YYYY-MM-DD");
                const startDateTwo = dayjs(this.chartDateTow[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateTwo = dayjs(this.chartDateTow[1]).format("YYYY-MM-DD");
                this.$fly
                    .post(queryEvaluationPropertyChartDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        dutyType,
                        startDateOne,
                        endDateOne,
                        startDateTwo,
                        endDateTwo,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.buildChart(res.data);
                    });
            },
            //获取人事图表数据 dutyType 1.入职2.离职
            getPersonnelChartData(queryType, dutyType) {
                const startDateOne = dayjs(this.chartDateOne[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateOne = dayjs(this.chartDateOne[1]).format("YYYY-MM-DD");
                const startDateTwo = dayjs(this.chartDateTow[0]).format(
                    "YYYY-MM-DD"
                );
                const endDateTwo = dayjs(this.chartDateTow[1]).format("YYYY-MM-DD");
                this.$fly
                    .post(queryAccountPropertyChartDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        startDateOne,
                        startDateTwo,
                        endDateOne,
                        endDateTwo,
                        queryType,
                        dutyType,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.buildChart(res.data);
                    });
            },
            //获取人事离职图表数据
            getPersonnelResignChartData(dutyType) {
                this.getPersonnelChartData(2, dutyType);
            },
            //获取人事入职图表数据
            getPersonnelOnboardingChartData(dutyType) {
                console.log(dutyType);
                this.getPersonnelChartData(1, dutyType);
            },
            queryUvPvByRegion() {
                const params = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    regionCode: this.$vc.getCurrentRegion().communityId,
                };
                this.$fly.post(queryUvPvByRegionUrl, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.buildingBookData = res.data;
                });
            },
            queryUV(count,id,item){
                //带过去的参数
                const params = {
                    startDate:this.startDate + " 00:00:00",
                    endDate:this.endDate +" 23:59:59",
                    uvCount:count,
                    userId:id,
                    name:`${item.name}【${item.jobTitle}】`
                };
                this.$router.push({
                    name:"uv",
                    params:params
                });
            },
            queryPV(count,id,item){
                //带过去的参数
                const params = {
                    startDate:this.startDate + " 00:00:00",
                    endDate:this.endDate +" 23:59:59",
                    pvCount:count,
                    userId:id,
                    name:`${item.name}【${item.jobTitle}】`
                };
                this.$router.push({
                    name:"pv",
                    params:params
                });
            }
        },
    };
</script>
<style scoped>
    .el-date-editor .el-range-input::placeholder {
        color: #999;
    }

    .result-panel {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    /*.data,
    .filter-panel {
        margin-left: 30px;
        margin-right: 30px;
    }*/
    .data ul {
        list-style: none;
        color: black;
        font-size: 14px;
        margin-left: 30pt;
        padding-left: 0px;
    }

    .data {
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .data > ul li {
        margin-top: 10px;
    }

    .data > ul > li:nth-child(1) {
        margin-top: 5px;
    }

    .data > ul li:nth-child(1) span:nth-child(1) {
        font-size: 24px;
        margin-right: 10px;
        font-weight: 600;
    }

    .data > ul ul li {
        float: left;
        margin-top: 0;
        width: 240px;
    }

    .data > ul ul li a {
        margin-left: 10px;
    }

    .data > ul li span,
    .data > ul li a {
        display: inline-block;
        vertical-align: bottom;
    }

    .data > ul li ul li:nth-child(1) {
        margin-left: 0px;
    }

    .data > ul ul {
        overflow: auto;
        margin: 0px;
    }

    .data.personnel > ul ul {
        padding: 15px 0;
    }

    .data.personnel > ul li:not(:last-of-type) ul {
        border-bottom: 1px solid #f0f0f0;
    }

    .data a {
        text-decoration: underline;
        color: #1dafff;
    }

    .building-book {
        margin-top: 20px;
        background: #fff;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        opacity: 1;
        overflow: hidden;
        padding-bottom: 10px;
    }
    .building-book table{
        width: 100%;
        margin: 10px 30px 20px 30px;
    }
    .building-book table tr{
        height: 33px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #FFFFFF;
        opacity: 1;
    }
    .building-book table tr td{
        height: 53px;
        opacity: 1;
        text-align: center;
        background-color: #00B694
    }

    .building-book table tbody{
        width: 72px;
        height: 33px;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        color: #000000;
        opacity: 1;
        text-align: center;
    }
    .building-book table tbody td{
        height: 63px;
        border: 1px solid #00B694;
        border-left: none;
        box-sizing: border-box;
        position: relative;
    }
    /*.building-book table tbody td:nth-child(1)::after{*/
    /*    content: "";*/
    /*    position: absolute;*/
    /*    width: 1px;*/
    /*    height: 63px;*/
    /*    background-color: #00B694;*/
    /*    left: 0;*/
    /*    top: 0;*/
    /*}*/
    /*.building-book table tbody:nth-last-child(1) td:last-child{*/
    /*   position: relative;*/
    /*}*/
    /*.building-book table tbody:nth-last-child(1) td:last-child::after{*/
    /*    content: "";*/
    /*    position: absolute;*/
    /*    border: 1px solid green;*/
    /*    width: 12px;*/
    /*    height: 12px;*/

    /*    border-right-color: #ffff;*/
    /*    border-bottom-color: #ffff;*/
    /*    border-top-left-radius: 26px;*/
    /*}*/
    /*.building-book table tbody:nth-last-child(1) td:nth-child(1){*/
    /*    border-radius: 6px;*/
    /*    background-color: red;*/
    /*}*/
    /*table tr:first-child td:last-child{*/
    /*    border-top-right-radius: 10px;!* 设置table右下圆角 *!*/
    /*}*/
    .table.modal-inner-table {
        --modal-inner-table-font-size:14px;
        --modal-inner-cell-min-width:50px;
    }

</style>
